import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1700px) {
      font-size: 75%;
    }

    @media (max-width: 300px) {
      font-size: 50%;
    }

    scroll-behavior: smooth;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  body {
    font-family: "Gotham", 'Inter', sans-serif;
    overflow-x: hidden;
  }

  button {
    outline: none;
    font-weight: bold;
    font-size: 1.1rem;
    cursor: pointer;
    padding: 1rem 2rem;
    transition: all 0.2s ease;
    border: 0;
    border-radius: 32px;
    font-family: "Gotham", 'Inter', sans-serif;

    &:hover {
      background-color: #ffd619;
    }
  }

  h1 {
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -1px;
    font-family: "Gotham", 'Inter', sans-serif;
    font-weight: 700;
  }

  h2 {
    font-weight: lighter;
    font-size: 4rem;
  }

  h4 {
    font-weight: bold;
    font-size: 2rem;
  }

  a {
    font-size: 1.1rem;
  }

  span {
    font-weight: bold;
  }

  p {
    padding: 3rem 0;
    color: #00414b;
    font-size: 1.4rem;
  }

`;

export default GlobalStyle;
