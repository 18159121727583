import {Flex, Heading} from "@aws-amplify/ui-react";
import GlobalStyle from "./components/GlobalStyle";
import Chart from "./components/Chart";

function App() {
    return (
        <div className="App">
            <GlobalStyle/>
            <Flex height={"100vh"} width={"100%"} justifyContent={"center"} alignItems={"center"} style={{display: "flex"}}>
                <Chart />
            </Flex>
        </div>
    );
}

export default App;
