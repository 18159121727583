import React, {useState} from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown';
import variableChart from 'highcharts/modules/variable-pie'

drilldown(Highcharts);
variableChart(Highcharts);

function Chart() {

    const [options, setOptions] = useState({
        accessibility: {
            enabled: false
        },
        title: {
            text: undefined
        },
        credits: {
            enabled: false
        },
        series: [
            {
                // https://api.highcharts.com/highcharts/series.pie.dataLabels.animation

                type: "pie",
                name: "Anzahl",
                dataLabels: {
                    enabled: true,
                    distance: -40,
                    style: {textDecoration: "none"}
                },
                data: [
                    {
                        drilldown: "cbr",
                        name: "CBR: 12",
                        y: 12,
                        color: "#FFEC48",
                        visible: true,
                        sliced: false,
                        selected: false
                    },
                    {
                        drilldown: "cbr2",
                        name: "CBR2",
                        y: 5,
                        color: "#FFEC48",
                        visible: true,
                        sliced: false,
                        selected: false
                    },
                    {
                        drilldown: "moneymate",
                        name: "MoneyMate: 7",
                        y: 7,
                        color: "#8AA638",
                        visible: true,
                        sliced: false,
                        selected: false
                    },
                    {
                        drilldown: "redirects",
                        name: "Überleitungen: 5",
                        y: 5,
                        color: "#F69707",
                        visible: true,
                        sliced: false,
                        selected: false
                    },
                    {
                        drilldown: "optins",
                        name: "Opt-In's: 32",
                        selected: false,
                        sliced: false,
                        visible: true,
                        y: 32,
                        color: "#BDDBE6"
                    }
                ]
            }
        ],
        summarized: false,
        drilldown: {
            activeDataLabelStyle: {
                textDecoration: 'none',
                color: "black",
            },
            series: [
                {
                    name: "Summe",
                    id: "cbr",
                    type: "pie",
                    innerSize: '70%',
                    dataLabels: {
                        enabled: true,
                        // format: '{y} €'
                    },
                    data: [
                        {
                            name: "Mehmet",
                            y: 13000,
                            color: "#7DA7D9",
                            visible: true,
                            sliced: false,
                            selected: false
                        },
                        {
                            name: "Mehmet",
                            y: 5000,
                            color: "#00ffea",
                            visible: true,
                            sliced: false,
                            selected: false
                        },
                        {
                            name: "Julian",
                            y: 15500,
                            color: "#C74542",
                            visible: true,
                            sliced: false,
                            selected: false
                        },
                        {
                            name: "Jenna",
                            y: 7000,
                            color: "#56c742",
                            visible: true,
                            sliced: false,
                            selected: false,
                        }
                    ]
                },
                {
                    name: "Summe",
                    id: "cbr2",
                    type: "pie",
                    innerSize: '70%',
                    dataLabels: {
                        enabled: true,
                        format: '{y} €'
                    },
                    data: [
                        {
                            name: "Mehmet",
                            y: 13000,
                            color: "#7DA7D9",
                            visible: true,
                            sliced: false,
                            selected: false
                        },
                        {
                            name: "Mehmet",
                            y: 5000,
                            color: "#00ffea",
                            visible: true,
                            sliced: false,
                            selected: false
                        },
                        {
                            name: "Julian",
                            y: 15500,
                            color: "#C74542",
                            visible: true,
                            sliced: false,
                            selected: false
                        },
                        {
                            name: "Jenna",
                            y: 7000,
                            color: "#56c742",
                            visible: true,
                            sliced: false,
                            selected: false,
                        }
                    ]
                },
            ]
        },
        boost: {
            enabled: false
        },
        legend: {
            enabled: false,
        },
        chart: {
            events: {
                drilldown: function (e) {
                    console.log("drilldown e: ", e);
                },
                drillup: function (e) {
                    console.log("drilled up: ", e);
                }
            }
        }
    });

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={options}/>
        </>
    )
}

export default Chart